// import react and gatsby libs
import React from 'react'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// import other components
import Layout from '../components/Layout'

// import assets
import bg from '../assets/404.jpg'

// styled Page element
const Page = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  div {
    background: #f7f7f7;
    padding: 2rem 8rem;
    border-radius: 3px;
  }

  h1 {
    font-size: 8rem;
    margin: 0;
    padding: 0;
    color: #aaa;
  }

  p {
    color: #555;
    line-height: 2rem;
    margin: 0;
    padding: 0;

    a {
      color: #222;
    }
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */

  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

export default () => (
  <Layout>
    <Page>
      <div>
        <h1>404</h1>
        <p>Page not found. <br /><a href="https://episcopalassetmap.org">Find a church instead?</a></p>
      </div>
    </Page>
  </Layout>
)
